import axios, { AxiosRequestConfig, Method } from 'axios';
import { REACT_APP_API_URL as basePath } from 'config';
import * as qs from 'qs';

const apiCall = async (
  method: Method,
  path: string,
  auth: boolean,
  body: any,
  getAllPath: boolean
) => {
  const header: any = {
    'Content-Type': 'application/json'
  };
  if (auth) {
    header.Authorization = `Bearer getTokenMethodToImplement`; // TODO
  }
  let url = `${basePath}${path}`;
  if (getAllPath) {
    url = path; // TOVERIFY
  }

  const config: AxiosRequestConfig<any> = {
    method,
    url,
    data: body,
    headers: header
  };
  if (!Object.keys(body).length) {
    delete config.data;
  }
  const response = await axios(config);
  return response;
};

const apiCallEncoded = async (url: string, body: any) => {
  const header: any = {
    'Content-Type': 'application/x-www-form-urlencoded'
  };
  const config: AxiosRequestConfig<any> = {
    method: 'post',
    url,
    data: qs.stringify(body),
    headers: header
  };
  const response = await axios(config);
  return response;
};

export const get = async (path: string, body = {}, auth = false, getAllPath = false) => {
  return await apiCall('get', path, auth, body, getAllPath);
};
export const put = async (path: string, body = {}, auth = true, getAllPath = false) => {
  return await apiCall('put', path, auth, body, getAllPath);
};
export const post = async (path: string, body = {}, auth = true, getAllPath = false) => {
  return await apiCall('post', path, auth, body, getAllPath);
};
export const postEncoded = async (url: string, body = {}) => {
  return await apiCallEncoded(url, body);
};
export const del = async (path: string, body = {}, auth = true, getAllPath = false) => {
  return await apiCall('delete', path, auth, body, getAllPath);
};
