import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'app/store';

const initialState: {
  isSummary: {
    data: boolean;
    status: string;
  };
  summary: {
    data: any;
    status: string;
  };
  isSummaryStep: {
    data: boolean;
    status: string;
  };
} = {
  isSummary: {
    data: false,
    status: 'idle'
  },
  summary: {
    data: null,
    status: 'idle'
  },
  isSummaryStep: {
    data: false,
    status: 'idle'
  }
};

export const summarySlice = createSlice({
  name: 'summary',
  initialState,
  reducers: {
    setIsSummary: (state, action) => {
      state.isSummary.data = action.payload;
    },
    setSummary: (state, action) => {
      state.summary.data = action.payload;
    },
    resetIsSummary: (state) => {
      state.isSummary.data = false;
    },
    resetSummary: (state) => {
      state.summary.data = null;
    },
    setIsSummaryStep: (state, action) => {
      state.isSummaryStep.data = action.payload;
    },
    resetIsSummaryStep: (state) => {
      state.isSummaryStep.data = false;
    }
  }
});

export const {
  setIsSummary,
  setSummary,
  resetIsSummary,
  resetSummary,
  setIsSummaryStep,
  resetIsSummaryStep
} = summarySlice.actions;
export const isSummaryState = (state: RootState) => state.summary.isSummary;
export const summaryState = (state: RootState) => state.summary.summary;
export const isSummaryStepState = (state: RootState) => state.summary.isSummaryStep;

export default summarySlice.reducer;
