// import { REACT_APP_API_URL } from 'config';
import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import ChainedBackend from 'i18next-chained-backend';
import HttpBackend from 'i18next-http-backend';
import resourcesToBackend from 'i18next-resources-to-backend';
import { initReactI18next } from 'react-i18next';
import { REACT_APP_API_URL } from 'config';
import translationEN from './locales/en.json';
import translationIT from './locales/it.json';
import translationES from './locales/es.json';

const userLang = 'it'; // navigator.language.split('-')[0]localStorage.getItem('i18nextLng') ??

export const getLanguage = () => {
  return userLang;
};

const localResources = {
  en: {
    translations: translationEN
  },
  it: {
    translations: translationIT
  },
  es: {
    translations: translationES
  }
};

i18next
  .use(ChainedBackend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    react: {
      useSuspense: true
    },
    backend: {
      backends: [HttpBackend, resourcesToBackend(localResources)],
      backendOptions: [
        {
          loadPath: `${REACT_APP_API_URL}/user/i18n/{{lng}}.json/shop`,
          crossDomain: true
        }
      ]
    },
    debug: process.env.NODE_ENV === 'development',
    fallbackLng: 'it',
    ns: ['translations'],
    defaultNS: 'translations',
    interpolation: {
      escapeValue: true
    }
  });

i18next.changeLanguage(userLang);

export default i18next;
