import { get } from 'utility/http';

export const getCountriesList = async () => {
  try {
    const response = await get('/bill/billing_countries');
    return response;
  } catch (error: any) {
    return Promise.reject(error.response ? error.response : error);
  }
};

export const getProvincesList = async () => {
  try {
    const response = await get('/bill/billing_province');
    return response;
  } catch (error: any) {
    return Promise.reject(error.response ? error.response : error);
  }
};
