import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { openToastify } from 'components/toast/OpenToast';
import { contractSliceInterface } from 'models/contractInterface';
import { getContract, getContractVerified, getPreviewContract } from 'services/stepsAPI';

const initialState: contractSliceInterface = {
  contract: {
    data: null,
    status: 'idle'
  },
  contractVerified: {
    data: null,
    status: 'idle'
  },
  contractPreview: {
    data: null,
    status: 'idle'
  },
  isContractPreview: {
    data: false,
    status: 'idle'
  },
  isContract: {
    data: false,
    status: 'idle'
  }
};

export const getContractState = createAsyncThunk('contract/getContract', async () => {
  try {
    const response: any = await getContract();
    openToastify('success', 'success');
    return response.data;
  } catch (error: any) {
    // openToastify('danger', 'something_missing', error?.status);
    return Promise.reject(error.data ? error.data : error);
  }
});

export const getContractVerifiedState = createAsyncThunk(
  'contract/getContractVerified',
  async () => {
    try {
      const response: any = await getContractVerified();
      return response.data;
    } catch (error: any) {
      // openToastify('danger', 'something_missing', error?.status);
      return Promise.reject(error.data ? error.data : error);
    }
  }
);

export const getPreviewContractState = createAsyncThunk('contract/getPreviewContract', async () => {
  try {
    const response: any = await getPreviewContract();
    return response.data;
  } catch (error: any) {
    // openToastify('danger', 'something_missing', error?.status);
    return Promise.reject(error.data ? error.data : error);
  }
});

export const contractSlice = createSlice({
  name: 'contract',
  initialState,
  reducers: {
    setIsContract: (state, action) => {
      state.isContract.data = action.payload;
    },
    setIsContractPreview: (state, action) => {
      state.isContractPreview.data = action.payload;
    },
    resetContract: (state) => {
      state.contract.data = null;
    },
    resetContractVerified: (state) => {
      state.contractVerified.data = null;
    },
    resetContractPreview: (state) => {
      state.contractPreview.data = null;
    },
    resetIsContract: (state) => {
      state.isContract.data = false;
    },
    resetIsContractPreview: (state) => {
      state.isContractPreview.data = false;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(getContractState.pending, (state) => {
        state.contract.status = 'loading';
      })
      .addCase(getContractState.fulfilled, (state, action) => {
        state.contract.status = 'success';
        state.contract.data = action.payload;
      })
      .addCase(getContractState.rejected, (state) => {
        state.contract.status = 'rejected';
      })
      .addCase(getContractVerifiedState.pending, (state) => {
        state.contractVerified.status = 'loading';
      })
      .addCase(getContractVerifiedState.fulfilled, (state, action) => {
        state.contractVerified.status = 'success';
        state.contractVerified.data = action.payload;
      })
      .addCase(getContractVerifiedState.rejected, (state) => {
        state.contractVerified.status = 'rejected';
      })
      .addCase(getPreviewContractState.pending, (state) => {
        state.contractPreview.status = 'loading';
      })
      .addCase(getPreviewContractState.fulfilled, (state, action) => {
        state.contractPreview.status = 'success';
        state.contractPreview.data = action.payload;
      })
      .addCase(getPreviewContractState.rejected, (state) => {
        state.contractPreview.status = 'rejected';
      });
  }
});

export const {
  setIsContractPreview,
  resetContract,
  setIsContract,
  resetIsContract,
  resetIsContractPreview,
  resetContractPreview
} = contractSlice.actions;
export const contractState = (state: RootState) => state.contract.contract;
export const contractVerified = (state: RootState) => state.contract.contractVerified;
export const contractPreviewState = (state: RootState) => state.contract.contractPreview;
export const isContractPreviewState = (state: RootState) => state.contract.isContractPreview;
export const isContractState = (state: RootState) => state.contract.isContract;

export default contractSlice.reducer;
