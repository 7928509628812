import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'app/store';

const initialState: {
  toast: {
    data: any;
    status: string;
  };
} = {
  toast: {
    data: null,
    status: 'idle'
  }
};

export const toastifySlice = createSlice({
  name: 'toastify',
  initialState,
  reducers: {
    setToast: (state, action) => {
      state.toast.data = action.payload;
    },
    resetToast: (state) => {
      state.toast.data = null;
    }
  }
});

export const { setToast, resetToast } = toastifySlice.actions;
export const toastState = (state: RootState) => state.toast.toast;

export default toastifySlice.reducer;
