import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit';
import homepageReducer from 'features/homepage/HomepageSlice';
import buildingReducer from 'features/homepage/steps/BuildingSlice';
import contactReducer from 'components/modal/help/ContactSlice';
import infoModalReducer from 'components/modal/info/InfoModalSlice';
import billingReducer from 'features/homepage/steps/billing/BillingSlice';
import quoteReducer from 'features/homepage/steps/quote/QuoteSlice';
import checkoutReducer from 'features/homepage/steps/stripe/CheckoutSlice';
import contractReducer from 'features/homepage/steps/contract/ContractSlice';
import preventiveReducer from 'features/homepage/steps/preventive/PreventiveSlice';
import summaryReducer from 'features/homepage/steps/summary/SummarySlice';
import toastReducer from 'components/toast/ToastifySlice';

export const store = configureStore({
  reducer: {
    homepage: homepageReducer,
    building: buildingReducer,
    contact: contactReducer,
    infoModal: infoModalReducer,
    billing: billingReducer,
    quote: quoteReducer,
    checkout: checkoutReducer,
    contract: contractReducer,
    preventive: preventiveReducer,
    summary: summaryReducer,
    toast: toastReducer
  }
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
