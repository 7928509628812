import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'app/store';

const initialState = {
  infoContent: {
    data: null,
    status: 'idle'
  },
  isInfo: {
    data: false,
    status: 'idle'
  }
};

export const infoModalSlice = createSlice({
  name: 'infoModal',
  initialState,
  reducers: {
    setInfoContent: (state, action) => {
      state.infoContent.data = action.payload;
    },
    setIsInfo: (state, action) => {
      state.isInfo.data = action.payload;
    },
    resetInfoContent: (state) => {
      state.infoContent.data = null;
      state.infoContent.status = 'idle';
    },
    resetIsInfo: (state) => {
      state.isInfo.data = false;
    }
  }
});

export const { setInfoContent, setIsInfo, resetInfoContent, resetIsInfo } = infoModalSlice.actions;
export const infoContentState = (state: RootState) => state.infoModal.infoContent;
export const isInfoState = (state: RootState) => state.infoModal.isInfo;

export default infoModalSlice.reducer;
