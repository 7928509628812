export const BUILDING_TYPE = 'building_type';
export const PACKAGE_TYPE = 'package_type';
export const INTERCOM = 'intercom';
export const CYLINDER_TYPE = 'cylinder_type';
export const PRODUCT = 'product';
export const DOMOTICS = 'domotics';

export const stepsApartment = [
  BUILDING_TYPE,
  PACKAGE_TYPE,
  INTERCOM,
  CYLINDER_TYPE,
  PRODUCT,
  DOMOTICS
];

export const ENTRANCE_TYPE = 'entrance_type';
export const ENTRANCE_SECOND = 'entrace_second';
export const PACKAGE_TYPE_ROOM = 'package_type_room';
export const CYLINDER_TYPE_ROOM = 'cylinder_type_room';

export const stepsRoomStructure = [
  BUILDING_TYPE,
  PACKAGE_TYPE,
  ENTRANCE_TYPE,
  INTERCOM,
  CYLINDER_TYPE,
  ENTRANCE_SECOND,
  PACKAGE_TYPE_ROOM,
  CYLINDER_TYPE_ROOM,
  DOMOTICS
];
