import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'app/store';

const initialState: {
  isContact: {
    data: boolean;
    status: string;
  };
} = {
  isContact: {
    data: false,
    status: 'idle'
  }
};

export const contactSlice = createSlice({
  name: 'contact',
  initialState,
  reducers: {
    setIsContact: (state, action) => {
      state.isContact.data = action.payload;
    },
    resetIsContact: (state) => {
      state.isContact.data = false;
    }
  }
});

export const { setIsContact, resetIsContact } = contactSlice.actions;
export const isContactState = (state: RootState) => state.contact.isContact;

export default contactSlice.reducer;
