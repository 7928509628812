import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'app/store';

const initialState: {
  isQuote: {
    data: boolean;
    status: string;
  };
} = {
  isQuote: {
    data: false,
    status: 'idle'
  }
};

export const quoteSlice = createSlice({
  name: 'quote',
  initialState,
  reducers: {
    setIsQuote: (state, action) => {
      state.isQuote.data = action.payload;
    },
    resetIsQuote: (state) => {
      state.isQuote.data = false;
    }
  }
});

export const { setIsQuote, resetIsQuote } = quoteSlice.actions;
export const quoteState = (state: RootState) => state.quote;
export const isQuoteState = (state: RootState) => state.quote.isQuote;

export default quoteSlice.reducer;
