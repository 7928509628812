/* eslint-disable @typescript-eslint/no-non-null-assertion */
import i18next, { getLanguage } from 'i18n/config';
import { get, post } from 'utility/http';

export const getLocalTypes = async () => {
  try {
    const response = await get('/ecommerce/catalogue/local_types?page=1&size=30');
    return response;
  } catch (error: any) {
    return Promise.reject(error.response ? error.response : error);
  }
};

export const getIntercomList = async () => {
  try {
    const response = await get('/ecommerce/catalogue/intercoms?page=1&size=30');
    return response;
  } catch (error: any) {
    return Promise.reject(error.response ? error.response : error);
  }
};

export const getCylinderType = async () => {
  try {
    const response = await get('/ecommerce/catalogue/lock_types?page=1&size=30');
    return response;
  } catch (error: any) {
    return Promise.reject(error.response ? error.response : error);
  }
};

export const getDomoticsList = async () => {
  try {
    const response = await get('/ecommerce/catalogue/products?domain=domotics&page=1&size=30');
    return response;
  } catch (error: any) {
    return Promise.reject(error.response ? error.response : error);
  }
};

export const postPreventive = async (body: any) => {
  try {
    const session = JSON.parse(localStorage.getItem('session')!);
    const response = await post(
      `/ecommerce/cart?session_id=${session.session_id}&lang=${i18next.language}`,
      body
    );
    return response;
  } catch (error: any) {
    return Promise.reject(error.response ? error.response : error);
  }
};

export const postInvoiceData = async (body: any) => {
  try {
    const session = JSON.parse(localStorage.getItem('session')!);
    const response = await post(`/ecommerce/invoice_data?session_id=${session.session_id}`, body);
    return response;
  } catch (error: any) {
    return Promise.reject(error.response ? error.response : error);
  }
};

export const postAddPayMethod = async (body: any) => {
  try {
    const session = JSON.parse(localStorage.getItem('session')!);
    const response = await post(`/ecommerce/pay?session_id=${session.session_id}`, body);
    return response;
  } catch (error: any) {
    return Promise.reject(error.response ? error.response : error);
  }
};

export const getPreviewContract = async () => {
  try {
    const session = JSON.parse(localStorage.getItem('session')!);
    const response = await get(
      `/ecommerce/paperwork/contract_sign/preview?session_id=${session.session_id}&lang=${i18next.language}`
    );
    return response;
  } catch (error: any) {
    return Promise.reject(error.response ? error.response : error);
  }
};

export const getContract = async () => {
  try {
    const session = JSON.parse(localStorage.getItem('session')!);
    const response = await get(
      `/ecommerce/paperwork/contract_sign?session_id=${session.session_id}&lang=${getLanguage()}`
    );
    return response;
  } catch (error: any) {
    return Promise.reject(error.response ? error.response : error);
  }
};

export const getContractVerified = async () => {
  try {
    const session = JSON.parse(localStorage.getItem('session')!);
    const response = await get(
      `/ecommerce/paperwork/contract_sign/check_status?session_id=${session.session_id}`
    );
    return response;
  } catch (error: any) {
    return Promise.reject(error.response ? error.response : error);
  }
};

export const getClientSecret = async () => {
  try {
    const session = JSON.parse(localStorage.getItem('session')!);
    const response = await post(`/ecommerce/payment_method?session_id=${session.session_id}`);
    return response;
  } catch (error: any) {
    return Promise.reject(error.response ? error.response : error);
  }
};
