import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
// import { openToastify } from 'components/toast/OpenToast';
import { buildingSliceInterface } from 'models/buildingInterface';
import {
  getCylinderType,
  getDomoticsList,
  getIntercomList,
  getLocalTypes
} from 'services/stepsAPI';
import { BUILDING_TYPE } from './room/steps';

const initialState: buildingSliceInterface = {
  roomList: {
    data: [],
    status: 'idle'
  },
  localTypesList: {
    data: null,
    status: 'idle'
  },
  intercomType: {
    data: null,
    status: 'idle'
  },
  intercomList: {
    data: [],
    status: 'idle'
  },
  cylinderType: {
    data: null,
    status: 'idle'
  },
  domoticsList: {
    data: [],
    status: 'idle'
  }
};

export const getLocalTypesState = createAsyncThunk('building/getLocalTypes', async () => {
  try {
    const response: any = await getLocalTypes();
    return response.data;
  } catch (error: any) {
    // openToastify('danger', `something_missing`, error?.status);
    return Promise.reject(error.data ? error.data : error);
  }
});

export const getIntercomListState = createAsyncThunk('building/getIntercomList', async () => {
  try {
    const response: any = await getIntercomList();
    return response.data;
  } catch (error: any) {
    // openToastify('danger', 'something_missing', error?.status);
    return Promise.reject(error.data ? error.data : error);
  }
});

export const getCylinderTypeState = createAsyncThunk('building/getCylinderType', async () => {
  try {
    const response: any = await getCylinderType();
    return response.data;
  } catch (error: any) {
    // openToastify('danger', 'something_missing', error?.status);
    return Promise.reject(error.data ? error.data : error);
  }
});

export const getDomoticsListState = createAsyncThunk('building/getDomoticsList', async () => {
  try {
    const response: any = await getDomoticsList();
    return response.data;
  } catch (error: any) {
    // openToastify('danger', 'something_missing', error?.status);
    return Promise.reject(error.data ? error.data : error);
  }
});

export const buildingSlice = createSlice({
  name: 'building',
  initialState,
  reducers: {
    setRoomList: (state, action) => {
      state.roomList.data = action.payload;
    },
    setCylinderType: (state, action) => {
      state.cylinderType.data = action.payload;
    },
    resetRoomList: (state) => {
      state.roomList.data = [
        {
          packageType: null,
          buildingType: {},
          intercomType: {},
          cylinderType: {},
          product: {},
          productList: [],
          count: 1,
          isDomotics: false,
          domoticsList: [],
          activeStep: BUILDING_TYPE,
          // rooms
          entranceType: null,
          otherEntrance: [],
          roomConfig: {
            packageType: null,
            cylinderType: {},
            product: {},
            productList: []
          },
          isDigital: true
        }
      ];
    }
  },
  extraReducers(builder) {
    builder
      .addCase(getLocalTypesState.pending, (state) => {
        state.localTypesList.status = 'loading';
      })
      .addCase(getLocalTypesState.fulfilled, (state, action) => {
        state.localTypesList.status = 'success';
        state.localTypesList.data = action.payload;
      })
      .addCase(getLocalTypesState.rejected, (state) => {
        state.localTypesList.status = 'rejected';
      })
      .addCase(getCylinderTypeState.pending, (state) => {
        state.cylinderType.status = 'loading';
      })
      .addCase(getCylinderTypeState.fulfilled, (state, action) => {
        state.cylinderType.status = 'success';
        state.cylinderType.data = action.payload;
      })
      .addCase(getCylinderTypeState.rejected, (state) => {
        state.cylinderType.status = 'rejected';
      })
      .addCase(getDomoticsListState.pending, (state) => {
        state.domoticsList.status = 'loading';
      })
      .addCase(getDomoticsListState.fulfilled, (state, action) => {
        state.domoticsList.status = 'success';
        state.domoticsList.data = action.payload;
      })
      .addCase(getDomoticsListState.rejected, (state) => {
        state.domoticsList.status = 'rejected';
      })
      .addCase(getIntercomListState.pending, (state) => {
        state.intercomList.status = 'loading';
      })
      .addCase(getIntercomListState.fulfilled, (state, action) => {
        state.intercomList.status = 'success';
        state.intercomList.data = action.payload;
      })
      .addCase(getIntercomListState.rejected, (state) => {
        state.intercomList.status = 'rejected';
      });
  }
});

export const { setRoomList, resetRoomList } = buildingSlice.actions;
export const buildingState = (state: RootState) => state.building;
export const roomListState = (state: RootState) => state.building.roomList;
export const localTypesListState = (state: RootState) => state.building.localTypesList;
export const intercomListState = (state: RootState) => state.building.intercomList;
export const cylinderTypeState = (state: RootState) => state.building.cylinderType;
export const domoticsListState = (state: RootState) => state.building.domoticsList;

export default buildingSlice.reducer;
