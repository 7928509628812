import React from 'react';

const NotFound = React.lazy(() => {
  return Promise.all([
    import('components/notFound/NotFound'),
    new Promise((resolve) => setTimeout(resolve, 1000))
  ]).then(([moduleExport]) => moduleExport);
});

const Homepage = React.lazy(() => import('features/homepage/Homepage'));
const RoomConfigurator = React.lazy(() => import('features/homepage/steps/room/RoomConfigurator'));
const Billing = React.lazy(() => import('features/homepage/steps/billing/Billing'));
const Contract = React.lazy(() => import('features/homepage/steps/contract/Contract'));
const Payment = React.lazy(() => import('features/homepage/steps/stripe/Payment'));
// const About = React.lazy(() => import('features/about/About'));
const OrderComplete = React.lazy(
  () => import('features/homepage/steps/contract/orderStatus/OrderComplete')
);
const SummaryPage = React.lazy(() => import('features/homepage/steps/summary/SummaryPage'));

const routes = [
  { path: '*', element: NotFound, name: 'pagenotfound', auth: false },
  {
    path: '',
    exact: true,
    element: Homepage,
    name: 'homepage',
    auth: false,
    children: [
      {
        path: '',
        element: RoomConfigurator,
        name: 'Room Configurator',
        auth: false
      },
      { path: 'summary', element: SummaryPage, name: 'summary', auth: false },
      { path: 'billing', element: Billing, name: 'billing', auth: false },
      { path: 'contract', element: Contract, name: 'contract', auth: false },
      { path: 'checkout', element: Payment, name: 'checkout', auth: false }
    ]
  },
  {
    path: '/order-complete',
    element: OrderComplete,
    name: 'complete',
    auth: false
  }
];

export default routes;
